<template>
<!-- Composant commun permettant d'éditer un attribut de type texte -->
<!-- Propriétés à passer au composant :
        - value: la valeur par défaut de l'attribut
        - field: le nom de l'attribut
        - max: le nombre de caractères maximal que peut contenir l'attribut
-->
  <div>
    <b-row>
      <b-col cols="12">
        <b-input type="text" :value="value" @change="onInput" size="sm" :maxlength="max"></b-input>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";

export default {
  mixins: [DetailUpdateMixin],
  props: {
    value: {
      type: String,
      default: null,
    },
    field: {
      type: String
    },
    max: {
      type: Number,
      default: 100
    }
  },
  methods: {
    onInput(value) {
      const data = {};
      data[this.field] = value;
      this.$store.dispatch(this.storeEditAction, data);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
